import React from "react";
import { PageProps } from "gatsby";
import { LayoutOnBoarding, SEO, CategoryView } from "@components";
import { useAuth } from "@hooks";
import { useMediaQuery } from "@material-ui/core";
import { LayoutMobileInvestments } from "@components/Investments/Layout";
import { getUserMissions } from "@apollo";
import { MissionsInfo } from "@data";

const creditosDeudas: React.FC<PageProps> = () => {
  useAuth({ redirectIfNotLogged: true });
  const isMobile = useMediaQuery("(max-width: 1240px)");
  const { userMissions } = getUserMissions();

  const missions = userMissions.filter((obj) => obj.mission.category == MissionsInfo.RetirementCategory.name)

  return (
    <>
      <SEO />
      {!isMobile ? (
        <LayoutOnBoarding activeRokinpedia>
          <CategoryView missions={missions} categoryInfo={MissionsInfo.RetirementCategory}/>
        </LayoutOnBoarding>
      ) : (
        <LayoutMobileInvestments activeIncent>
          <CategoryView missions={missions} categoryInfo={MissionsInfo.RetirementCategory}/>
        </LayoutMobileInvestments>
      )}
    </>
  );
};

export default creditosDeudas;
